<template>
  <Modal v-model="show">
    <Login @done="closeModal"></Login>
  </Modal>
</template>

<script>
import ModalPlugin from '../../helpers/LoginModalPlugin'
import Modal from './Modal'
import Login from './Login'

export default {
  components: {
    Login,
    Modal,
  },
  data: () => ({
    show: false,
    onSuccess: {},
  }),
  methods: {
    closeModal() {
      this.show = false

      if (typeof this.onSuccess === 'function') {
        this.onSuccess()
      }
    },
    showModal(params) {
      this.onSuccess = params?.onSuccess
      this.show = true
    },
  },
  beforeMount() {
    ModalPlugin.EventBus.$on('show', (params) => {
      this.showModal(params)
    })
  },
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;
}
</style>
