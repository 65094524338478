<template>
  <div>
    <div v-html="page.content"></div>
    <PortfolioCards
      textLeft="Portrait"
      :urlLeft="getUrlForId('2945e411-bbd8-456e-8b8f-4d7c252599af')"
      textRight="Sensual"
      :urlRight="getUrlForId('4c6122c5-f25c-4549-b6ba-bba1cb424f85')"
    ></PortfolioCards>
    <!-- <x-portfoli-modern
      textLeft="Freunde"
      :urlLeft="getUrlForId('f0c8a5c4-fce1-4c8a-bf73-8e8c7e156a57')"
      textRight="Paare"
      :urlRight="getUrlForId('a1caf970-8c17-4dbc-9f18-92263d2dad62')"
    ></x-portfoli-modern> -->
  </div>
</template>

<script>
import PortfolioCards from '../components/custom/PortfolioModern'
import contentService from '../services/content'

export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PortfolioCards,
  },
  methods: {
    getUrlForId(id) {
      return contentService.getAssetsURL(id, 'reviews')
    },
  },
}
</script>
