<template>
  <button :type="type" :name="name" @click="$emit('btnClicked')">
    <span v-if="this.text" class="button__text">{{ this.text }}</span>
    <span class="button__icon">
      <fa-icon :icon="faicon" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'btn',
    },
    type: {
      type: String,
      default: 'button',
    },
    faicon: {
      type: String,
      default: 'fas-check',
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  display: flex;
  height: 50px;
  background: #e6af91a2;
  border: none;
  outline: none;
  overflow: hidden;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}

button:hover {
  background: #d48a63a1;
}

button:active {
  background: #d3551178;
}

.button__text {
  font-size: 13px;
  // transform: translateY(-3px);
}

.button__text,
.button__icon {
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  letter-spacing: 1px;
  height: 100%;
}

.button__icon {
  font-size: 23px;
  background: #d48b636c;
}
</style>
