<template>
  <div class="content">
    <PhotoGallery :images="images"> </PhotoGallery>
  </div>
</template>

<script>
import contentService from '../services/content'
import PhotoGallery from '../components/custom/PhotoGallery'

export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PhotoGallery,
  },
  data: () => ({
    images: [],
  }),
  mounted() {
    this.page.images.forEach((singleFile) => {
      const file = singleFile.directus_files_id
      var orientation = ''

      if (file['width'] > file['height']) {
        orientation = 'Landscape'
      } else {
        orientation = 'Portrait'
      }

      this.images.unshift({
        url: contentService.getAssetsURL(file.id, 'gallery'),
        orient: orientation,
        id: singleFile.id,
      })
    })

    // Sortiere nach Namen
    this.images.sort((a, b) => {
      return b.id - a.id
    })
  },
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 1280px;
  margin: 0 auto;
}
</style>
