<template>
  <div>
    <Lightbox class="carousel" :images="images" :intervall="5000" :speed="2"></Lightbox>

    <div class="info-box" v-html="page.content"></div>

    <Portfolio textLeft="Portrait" :urlLeft="getUrlForId('2945e411-bbd8-456e-8b8f-4d7c252599af')" textRight="Sensual" :urlRight="getUrlForId('4c6122c5-f25c-4549-b6ba-bba1cb424f85')" />
    <div class="info-box">
      <p>&nbsp;</p>
      <h1 style="text-align: center; margin: 50px 0 -5px 0;">Erfahrungen</h1>
      <p>&nbsp;</p>
      <p style="text-align: center;">
        Am wichtigsten ist es mir, dass das Shooting f&uuml;r dich ein
        besonderes Erlebnis ist und du dich in deiner Haut und vor meiner Kamera
        wohl f&uuml;hlst. Wenn du dich auf und &uuml;ber die Bilder freust,
        zeigt es mir, dass ich meine Sache gut gemacht habe. Daher ist mir deine
        Meinung ganz wichtig. Ich freue mich sehr &uuml;ber eine
        R&uuml;ckmeldung von dir. So kannst du deine Erfahrungen teilen und
        andere Menschen motivieren, sich ebenfalls f&uuml;r ein Shooting zu
        entscheiden.
      </p>
      <p style="text-align: center;">&nbsp;</p>
    </div>
    <Reviews></Reviews>
  </div>
</template>

<script>
import contentService from '../services/content'

export default {
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    slides: [],
  }),
  components: {
    Lightbox: () => import('../components/custom/Carousel'),
    Portfolio: () => import('../components/custom/PortfolioModern'),
    Reviews: () => import('../components/custom/Reviews'),
  },
  computed: {
    images () {
      return this.slides
    }
  },
  methods: {
    getUrlForId(id) {
      return contentService.getAssetsURL(id, 'reviews')
    },
  },
  mounted() {
    contentService
      .getItems(
        'carousels_directus_files',
        'filter[carousels_id]=' + this.settings.carousel
      )
      .then((result) => {
        result.data.forEach((file) => {
          this.slides.push({
            url: contentService.getAssetsURL(
              file.directus_files_id,
              'carousel'
            ),
          })
        })
      })
  },
}
</script>

<style lang="scss" scoped>
.sub-title,
.main-title {
  max-width: 1280px;
  margin: 0 auto;
  text-align: center;
}

.sub-title {
  margin-top: 15px;
}

.main-title {
  margin-bottom: 60px;
}

.info-box {
  max-width: 960px;
  margin: 0 auto;
}

hr {
  border-top: transparent;
  border-color: #9b8d76;
  margin: 10px -10px;
}

.carousel {
  width: 100%;
  padding: 0;
  margin-top: 50px;

  @media (min-width: 768px) {
    margin-top: -60px;
  }
}

.carousel::v-deep .VueCarousel-dot:focus {
  outline: none !important;
}
</style>
