<template>
  <div class="content">
    <Carousel
      class="carousel"
      ref="carousel"
      :perPage="1"
      :navigationEnabled="true"
      :paginationEnabled="false"
      navigationNextLabel='<fa-icon class="fa carousel-nav-btn fa-angle-right" icon="angle-right" />'
      navigationPrevLabel='<fa-icon class="fa carousel-nav-btn fa-angle-left" icon="angle-left" />'
    >
      <Slide v-for="(review, index) in reviews" :key="index">
        <div class="wrapper">
          <XImage
            class="image"
            :url="review.image"
            :title="review.model"
            :isMouseOver="false"
          ></XImage>
          <p>{{ review.review }}</p>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import XImage from '../base/Image'
import contentService from '../../services/content'

export default {
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    reviews: [],
  }),
  components: {
    Carousel,
    Slide,
    XImage,
  },
  mounted() {
    contentService.getItems('reviews', '').then((result) => {
      result.data.forEach((reviewentries) => {
        this.reviews.unshift({
          model: reviewentries.title,
          review: reviewentries.review,
          image: contentService.getAssetsURL(reviewentries.file, 'reviews'),
        })
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.content {
  background: #e6af916c;

  .wrapper {
    position: relative;
    display: flex wrap;
    flex-flow: center;
    height: 100%;
    justify-content: center;
    align-items: center;

    .image {
      width: 100%;
      padding: 5px 5px 2px 5px;
    }

    p {
      text-align: center;
      margin: 15px 5px;

      @media (min-width: 768px) {
        width: 30%;
        margin: 5px 10px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
    }
  }
}

.carousel::v-deep .VueCarousel-dot {
  outline: none;
}

.carousel::v-deep .fa {
  font-size: 20px;
}

.carousel::v-deep .VueCarousel-navigation {
  position: absolute;
  bottom: 2%;
  width: 100%;
  height: 20px;
}

.carousel::v-deep .VueCarousel-navigation-next {
  right: 8%;
  outline: none;
}

.carousel::v-deep .VueCarousel-navigation-prev {
  left: 8%;
  outline: none;
}

@media (min-width: 768px) {
  .carousel::v-deep .VueCarousel-navigation-next {
    right: 4%;
    outline: none;
  }

  .carousel::v-deep .VueCarousel-navigation-prev {
    left: 80%;
    outline: none;
  }
}
</style>
