<template>
  <div class="content">
    <div v-html="page.content"></div>
    <Reviews></Reviews>
  </div>
</template>

<script>
import Reviews from '../components/custom/Reviews'

export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Reviews,
  },
}
</script>
