<template>
  <div class="content">
    <div class="info-box">
      <h2 style="text-align: center; margin-left: 10px;">
        {{ shooting.name + ' Shooting vom ' + shooting.date }}
      </h2>
      <h3 style="text-align: center; margin-left: 10px;">
        {{ 'Download (~' + (totalFileSize / 1024 / 1024).toFixed(2) + 'MB)' }}
      </h3>
      <!-- <BasicButton
        @btnClicked="downloadAll"
        class="download-btn"
        faicon="download"
        :text="'Download (~' + (totalFileSize / 1024 / 1024).toFixed(2) + 'MB)'"
      ></BasicButton> -->

      <FlyingButton @btnClicked="downloadAll" faicon="download"> </FlyingButton>
      <!-- <div class="wrapper">
        <div class="content-left">
          <h2>Datum: {{ shooting.date }}</h2>
          <h2>Anzahl Bilder: {{ images.length }}</h2>
        </div>
        <div class="content-right">

        </div>
      </div> -->
    </div>
    <PhotoGallery
      :allowDownload="true"
      :images="images"
      buttonPosition="left"
      :showImageCount="true"
    ></PhotoGallery>
  </div>
</template>

<script>
import contentService from '../services/content'
import PhotoGallery from '../components/custom/PhotoGallery'
// import BasicButton from '../components/base/Button'
import FlyingButton from '../components/base/FlyingButton'

export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    PhotoGallery,
    // BasicButton,
    FlyingButton,
  },
  data: () => ({
    images: [],
    shooting: {},
    totalFileSize: 0,
  }),
  methods: {
    downloadAll() {
      contentService.downloadShooting(this.shooting.id)
    },
  },
  mounted() {
    contentService.getItems('shooting', this.$route.params.id).then(
      (resp) => {
        this.shooting = resp[0]
        resp[0].images.forEach((singleFile) => {
          const file = singleFile.directus_files_id

          var orientation = ''

          if (file['width'] > file['height']) {
            orientation = 'Landscape'
          } else {
            orientation = 'Portrait'
          }

          this.totalFileSize += file.filesize

          this.images.unshift({
            download_filename: file.filename_download,
            filename:
              file.filename_download.substring(
                0,
                file.filename_download.lastIndexOf('.')
              ) || file.filename_download,
            filesize: file.filesize,
            download_url: contentService.getAssetsURL(file.id),
            url: contentService.getAssetsURL(file.id, 'gallery'),
            orient: orientation,
          })
        })

        // Sortiere nach Namen
        this.images.sort((a, b) => {
          var nameA = a.download_filename.toUpperCase()
          var nameB = b.download_filename.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        })
      },
      (error) => {
        console.log(error)
      }
    )
  },
}
</script>

<style lang="scss" scoped>
.content {
  max-width: 1280px;
  margin: 10px auto;
  position: sticky;

  h3 {
    margin: 5px;
  }

  .info-box {
    display: flex wrap;
    align-items: center;
    border: 1px solid rgb(0, 0, 0);
    margin-bottom: 25px;
    padding: 15px;

    .download-btn {
      margin: 0 auto;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;

      .download-btn {
        margin: 0;
      }
    }
  }
}
</style>
