import LoginModal from '../components/custom/LoginModal'

const Modal = {
  // eslint-disable-next-line
  install(Vue, options) {
    this.EventBus = new Vue()

    Vue.component('x-login-modal', LoginModal)

    Vue.prototype.$modal = {
      show(params) {
        Modal.EventBus.$emit('show', params)
      },
    }
  },
}

export default Modal
