<template>
  <div class="wrapper" v-lazyload>
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <div class="image" :data-url="url" @click="imageClicked()"></div>
  </div>
</template>

<script>
import LazyLoadDirective from '../../helpers/LazyLoadDirective'

export default {
  props: {
    url: {
      required: true,
      type: String,
    },
  },
  directives: {
    lazyload: LazyLoadDirective,
  },
  methods: {
    isDirectiveActive() {
      if (this.isAnimated == false) {
        return false
      } else if (this.isMouseOver == true) {
        return false
      } else {
        return true
      }
    },
    getAnimationClasses() {
      if (this.isAnimated == false) {
        return 'loaded'
      } else {
        return ''
      }
    },
    imageClicked() {
      this.$emit('imageClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  background: #e6af916c;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;

  .image {
    opacity: 0;
    cursor: pointer;
    user-select: none;
    position: absolute;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100%;
    width: 100%;
  }

}

.loaded .image {
  opacity: 1;

  transition: all 0.25s ease-in-out;
}

.loaded .lds-ripple {
  display: none;
}

.lds-ripple {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.6, 0.6, 1.2) infinite;
  background: coral;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
</style>
