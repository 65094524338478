<template>
  <div
    v-scroll="isDirectiveActive"
    v-bind:class="getAnimationClasses"
    class="container"
  >
    <img
      v-scroll="isDirectiveActive"
      v-bind:class="getAnimationClasses"
      @click="imageClicked()"
      :src="url"
      alt=""
    />
    <div
      v-scroll="isDirectiveActive"
      v-bind:class="getAnimationClasses"
      class="border"
    >
      <span class="span-border"></span>
      <span class="span-border"></span>
      <span class="span-border"></span>
      <span class="span-border"></span>
      <span
        class="span-text"
        v-if="title"
        v-scroll="isDirectiveActive"
        v-bind:class="getAnimationClasses"
      >
        {{ title }}
      </span>
    </div>
  </div>
</template>

<script>
import ScrollIntoViewDirective from '../../helpers/ScrollIntoViewDirective'

export default {
  props: {
    url: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    isAnimated: {
      type: Boolean,
      default: true,
    },
    isMouseOver: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    scroll: ScrollIntoViewDirective,
  },
  methods: {
    isDirectiveActive() {
      if (this.isAnimated == false) {
        return false
      } else if (this.isMouseOver == true) {
        return false
      } else {
        return true
      }
    },
    getAnimationClasses() {
      if (this.isAnimated == false) {
        return 'loaded'
      } else {
        return ''
      }
    },
    imageClicked() {
      this.$emit('imageClicked')
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  overflow: hidden;

  .loaded {
    transition: all 250ms ease-out;
    transform: translateX(0);
  }
}

.container {
  .border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    margin: 3.5% 5%;

    .span-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      color: white;
      font-size: 23px;
      pointer-events: none;
      transition: all 0.5s ease-in;
      text-shadow: 0.5px 0.5px #252525;
      font-weight: normal;
      opacity: 0;
      transform: translateX(-200px);

      @media (min-width: 768px) {
        font-size: 28px;
      }
    }

    .span-border {
      display: block;
      position: absolute;
      background: white;
    }
    .span-border:nth-child(1) {
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 0.5s;
      transition-delay: 0.5s;
    }
    .span-border:nth-child(2) {
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s;
      transition-delay: 0.5s;
    }
    .span-border:nth-child(3) {
      right: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      transform: scaleY(0);
      transform-origin: bottom;
      transition: transform 0.5s;
    }
    .span-border:nth-child(4) {
      left: 0;
      top: 0;
      width: 100%;
      height: 1px;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s;
    }
  }
}

.loaded,
.container:hover {
  .border {
    .span-border:nth-child(1) {
      transform: scaleY(1);
      transform-origin: bottom;
      transition: transform 0.5s;
    }
    .span-border:nth-child(2) {
      transform: scaleX(1);
      transform-origin: left;
      transition: transform 0.5s;
    }
    .span-border:nth-child(3) {
      transform: scaleY(1);
      transform-origin: bottom;
      transition: transform 0.5s;
      transition-delay: 0.5s;
    }
    .span-border:nth-child(4) {
      transform: scaleX(1);
      transform-origin: left;
      transition: transform 0.5s;
      transition-delay: 0.5s;
    }
    .span-text {
      opacity: 1;
      transition: all 500ms ease-out;
      transform: translateX(0);
      transition-delay: 150ms;
    }
  }
}
</style>
