<template>
  <div class="wrapper" v-touch:swipe="swipeHandler" v-touch:longtap="longtapHandler" >
    <ImageCount v-if="showImageCount" :imageCount="currentIndex + 1 + '/' + images.length" />
    <Headline :headLine="currentImage.filename" />
    <img v-for="(image, id) in images" :key="id" v-bind:class="{ active: id == currentIndex, inactive: id != currentIndex }" :src="getImageUrl(image, id)" />
    <DownloadNav v-if="allowDownload" @download="downloadImage" />
    <Nav withNav @prev="prev" @next="next"></Nav>
  </div>
</template>

<script>
import contentService from '../../services/content'

export default {
  props: {
    images: {
      required: true,
      type: Array,
    },
    index: {
      type: Number,
      default: 0,
    },
    showImageCount: {
      type: Boolean,
      default: false,
    },
    allowDownload: {
      type: Boolean,
      default: false,
    },
    withNav: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Headline: () => import('./LightboxHeadline'),
    Nav: () => import('./LightboxNav'),
    DownloadNav: () => import('./LightboxDownloadNav'),
    ImageCount: () => import('./LightboxImageCount'),
  },
  computed: {
    currentImage() {
      if (this.images && this.images.length > 0) {
        return this.images[this.currentIndex]
      }
      return { filename: '' }
    },
  },
  data: () => ({
    currentIndex: 0,
    orientation: "portrait",
  }),
  methods: {
    hasNext() {
      return this.currentIndex + 1 < this.images.length
    },
    hasPrev() {
      return this.currentIndex - 1 >= 0
    },
    next() {
      if (this.hasNext()) {
        this.currentIndex++
      }
    },
    prev() {
      if (this.hasPrev()) {
        this.currentIndex--
      }
    },
    swipeHandler(direction) {
      if (direction === 'left') {
        this.next()
      } else if (direction === 'right') {
        this.prev()
      }
    },
    longtapHandler() {
      this.downloadImage()
    },
    getImageUrl(image, id) {
      if (this.currentIndex == id || this.currentIndex + 1 == id || this.currentIndex - 1 == id) {
        return image.url
      } 

      return ''
    },
    downloadImage() {
      if (this.allowDownload) {
        var image = this.images[this.currentIndex]
        contentService.downloadImage(
          image.download_url,
          image.download_filename
        )
      }
    },
  },
  mounted() {
    this.currentIndex = this.index
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
}

img {
  position: absolute;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  transition: all 1s ease-out;
  max-height: 90%;
  height: 100%;
}

img.active {
  opacity: 1;
  visibility: visible;
}

img.inactive {
  opacity: 0;
}
</style>
