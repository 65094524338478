<template>
  <div v-if="!firstLogIn">
    <form class="wrapper" @submit="submit">
      <img src="../../assets/login-avatar.png" alt="" />
      <h1>Login</h1>
      <Input class="input" name="Email" id="email" />
      <Input class="input" type="password" name="Passwort" id="password" />
      <p class="error" v-if="showError">E-Mail oder Passwort ist nicht korrekt.</p>
      <p class="success" v-if="showSuccess">Vielen Dank und viel Spaß!</p>
      <Button class="submit-btn" faicon="sign-in-alt" text="Login" type="submit" />
      <a href="#" class="forgot-password">Passwort vergessen?</a>
    </form>
  </div>
  <div v-else>
    <form class="wrapper" @submit="changePassword">
      <img src="../../assets/login-avatar.png" alt="" />
      <h1>Passwort festlegen</h1>
      <Input class="input" type="password" name="Neues Passwort" id="newpassword" />
      <p class="success" v-if="showSuccess">Dein Passwort wurde nun geändert. Vielen Dank und viel Spaß!</p>
      <Button class="submit-btn" faicon="sign-in-alt" text="Festlegen" type="submit" />
    </form>
  </div>
</template>

<script>
import Input from '../base/Input'
import Button from '../base/Button'
import { AUTH_REQUEST, AUTH_CHANGE_PASSWORD } from '../../store/actions/auth'

export default {
  data: () => ({
    user: '',
    showError: false,
    showSuccess: false,
    firstLogIn: false,
  }),
  components: {
    Input,
    Button,
  },
  methods: {
    submit(event) {
      event.preventDefault()

      this.user = {
        email: event.target.elements.email.value,
        password: event.target.elements.password.value,
      }
      this.login(this.user)
    },
    changePassword(event) {
      event.preventDefault()

      this.user.password = event.target.elements.newpassword.value

      this.$store
        .dispatch(AUTH_CHANGE_PASSWORD, this.user.password)
        .then(() => {
          this.showSuccess = true
          setTimeout(() => {
            this.login(this.user)
          }, 1000)
        })
    },
    login(user) {
      this.$store.dispatch(AUTH_REQUEST, user).then(
        (resp) => {
          if (resp.isfirstlogin) {
            this.firstLogIn = true
          } else {
            this.showSuccess = true
            setTimeout(() => {
              this.$router.push('/meine-bilder')
              this.$emit('done')
            }, 50)
          }
        },
        () => {
          this.showError = true
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 380px;
  padding-top: 40px;

  img {
    position: absolute;
    top: -150px;
    left: calc(50% - 50px);
    width: 100px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }
  .input {
    margin: 25px 0;
  }

  .submit-btn {
    margin: 15px 0 15px auto;
  }

  h1 {
    text-align: center;
  }

  p {
    text-align: center;
  }

  .error {
    padding: 5px 0px;
    color: red;
  }

  .success {
    padding: 5px 0px;
    color: rgb(95, 168, 211);
  }
}
</style>
