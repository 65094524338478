import api from '../helpers/d9API'
import store from '../store/index'

export default {
  buildUrl(base, filter) {
    if (!isNaN(filter)) {
      return base + '/' + filter
    } else if (!filter) {
      return base
    }

    return base + '?' + filter
  },
  getPages(filter) {
    return this.getItems('pages', filter)
  },
  getResolvedItems(collection, filter, fields) {
    return api.get('items/' + collection + filter + fields).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  getResolvedPages(filter, fields) {
    return api.get('items/pages/' + filter + fields).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  getLayouts(filter) {
    return this.getItems('layouts', filter)
  },
  getItems(collection, filter) {
    return api.get(this.buildUrl('items/' + collection, filter)).then(
      (response) => {
        return Promise.resolve(response.data)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  post(route, data) {
    return api.post(route, data).then(
      (response) => {
        return Promise.resolve(response)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  getAssetsURL(id, asset) {
    if (asset == null) {
      return api.defaults.baseURL + '/assets/' + id
    }

    return api.defaults.baseURL + '/assets/' + id + '?key=' + asset
  },
  downloadImage(url, filename) {
    api.get(url, { responseType: 'blob' }).then((resp) => {
      const blob = new Blob([resp.data], { type: 'image/jpeg' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    })
  },
  downloadShooting(shootingId) {
    const token = store.getters.accessToken

    if (token && shootingId) {
      const url = api.defaults.baseURL + '/items/shooting/' + shootingId + '/' + token
      window.open(url)
    }
  }
}
