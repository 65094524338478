import axios from 'axios'
import store from '@/store/index'
import { version } from '../../package.json'

const BACKEND_URL = 'https://cms.evgenijhenning.de/' //'https://cms.evgenijhenning.de/' //"http://localhost:3000/api"

const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 5000,
})

instance.interceptors.request.use((config) => {
  if (store.getters.isAuthenticated) {
    const token = store.getters.accessToken
    config.headers.Authorization = 'Bearer ' + token
  }

  config.headers.FrontendVersion = version

  return config
})

instance.interceptors.response.use(resp => {
    return resp
  }, error => {
  if (error.response) {
    return Promise.reject(error.response)
  }
})

export default instance
