import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_CHANGE_PASSWORD } from '../actions/auth'

import contentService from '../../services/content'

const state = {
  name: localStorage.getItem('userName') || '',
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  status: '',
}

const getters = {
  isAuthenticated: state => !!state.accessToken,
  accessToken: state => state.accessToken,
  userName: state => state.name,
}

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      contentService.post('login', user).then(
        resp => {
          localStorage.setItem('accessToken', resp.data.accessToken)
          localStorage.setItem('refreshToken', resp.data.refreshToken)
          localStorage.setItem('userName', resp.data.name)
          commit(AUTH_SUCCESS, resp)
          resolve(resp.data)
        },
        error => {
          commit(AUTH_ERROR, error)
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('userName')
          reject(error)
        }
      )
    })
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    commit(AUTH_LOGOUT)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userName')
  },
  [AUTH_CHANGE_PASSWORD]: ({ dispatch }, password) => {
    contentService.post('changepassword', { password: password }).then(() => {
      dispatch(AUTH_LOGOUT)
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.accessToken = resp.data.accessToken
    state.refreshToken = resp.data.refreshToken
    state.name = resp.data.name
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: state => {
    state.accessToken = ''
    state.refreshToken = ''
  },
  [AUTH_CHANGE_PASSWORD]: state => {
    state.accessToken = ''
    state.refreshToken = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
