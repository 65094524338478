export default {
  inserted: el => {
    function loadImage() {
      let imageElement = Array.from(el.children).find(element => element.classList.contains('image'))

      // if (imageElement == null) imageElement = el

      if (imageElement) {
        let preloaderImg = document.createElement('img')
        preloaderImg.src = imageElement.dataset.url

        preloaderImg.addEventListener('load', () => {
          imageElement.style.backgroundImage = 'url(' + imageElement.dataset.url + ')'
          el.classList.add('loaded')
          preloaderImg = null
        })
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return
        } else {
          loadImage()
          observer.unobserve(el)
        }
      })
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      }

      const observer = new IntersectionObserver(handleIntersect, options)

      observer.observe(el)
    }

    if (!window['IntersectionObserver']) {
      loadImage()
    } else {
      createObserver()
    }
  },
}
