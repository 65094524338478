<template>
  <div class="content">
    <div class="wrapper">
      <div class="container">
        <XImage
          class="card"
          :url="urlLeft"
          :title="textLeft"
          :isMouseOver="isDesktopView()"
          @imageClicked="navTo('portfolio/portrait')"
        ></XImage>
      </div>
      <div class="container">
        <XImage
          class="card"
          :url="urlRight"
          :title="textRight"
          :isMouseOver="isDesktopView()"
          @imageClicked="navTo('portfolio/sensual')"
        ></XImage>
      </div>
    </div>
  </div>
</template>

<script>
import XImage from '../base/Image'

export default {
  props: {
    urlLeft: String,
    textLeft: String,
    urlRight: String,
    textRight: String,
  },
  components: {
    XImage,
  },
  methods: {
    navTo(url) {
      this.$router.push(url)
    },
    isDesktopView() {
      return window.innerWidth > 768
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex wrap;
  flex-flow: center;

  @media (min-width: 768px) {
    display: flex;
  }

  .container {
    display: flex;
    flex-flow: row wrap;
    margin: 2px;

    .card {
      cursor: pointer;
    }
  }
}
</style>
