<template>
  <div class="gallery-wrapper">
    <div class="gallery-container" v-for="(imageSet, index) in imageSets" :key="index" >
      <PhotoGallerySet :imageSet="imageSet" @imageClicked="loadLightbox"/>
    </div>
    <FullscreenModal v-model="showLightbox" :buttonPosition="buttonPosition">
      <Lightbox :index="lightboxIndex" :images="images" :allowDownload="allowDownload" :showImageCount="showImageCount" @closeLightbox="showLightbox = false" />
    </FullscreenModal>
  </div>
</template>

<script>
import PhotoGallerySet from './PhotoGallerySet'
import FullscreenModal from './FullscreenModal'
import Lightbox from '../lightbox/Lightbox'

export default {
  props: {
    images: [],
    allowDownload: {
      defaultStatus: false,
    },
    buttonPosition: {
      type: String,
      default: 'center',
    },
    showImageCount: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    lightboxIndex: 0,
    showLightbox: false,
  }),
  components: {
    PhotoGallerySet,
    FullscreenModal,
    Lightbox,
  },
  computed: {
    imageSets() {
      const imageSets = []

      for (let i = 0; i < this.images.length; i++) {
        if (this.images[i + 1] == null) {
          if (this.images[i].orient == 'Landscape') {
            imageSets.push({
              url1: this.images[i].url,
              imageIndex1: i,
              class: 'land',
            })
          } else {
            imageSets.push({
              url1: this.images[i].url,
              imageIndex1: i,
              class: 'port',
            })
          }
        } else if (
          this.images[i].orient == 'Landscape' &&
          this.images[i + 1].orient == 'Landscape'
        ) {
          imageSets.push({
            url1: this.images[i].url,
            imageIndex1: i,
            class: 'land',
          })
        } else if (
          this.images[i].orient == 'Landscape' &&
          this.images[i + 1].orient == 'Portrait'
        ) {
          imageSets.push({
            url1: this.images[i].url,
            imageIndex1: i,
            url2: this.images[i + 1].url,
            imageIndex2: i + 1,
            class: 'land-port',
          })
          i++
        } else if (
          this.images[i].orient == 'Portrait' &&
          this.images[i + 1].orient == 'Landscape'
        ) {
          imageSets.push({
            url1: this.images[i].url,
            imageIndex1: i,
            url2: this.images[i + 1].url,
            imageIndex2: i + 1,
            class: 'port-land',
          })
          i++
        } else if (
          this.images[i].orient == 'Portrait' &&
          this.images[i + 1].orient == 'Portrait'
        ) {
          imageSets.push({
            url1: this.images[i].url,
            imageIndex1: i,
            url2: this.images[i + 1].url,
            imageIndex2: i + 1,
            class: 'port-port',
          })
          i++
        }
      }

      return imageSets
    },
  },
  methods: {
    loadLightbox(imageIndex) {
      this.lightboxIndex = imageIndex
      this.showLightbox = true
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery-wrapper {
  display: flex wrap;
  flex-direction: row;
  flex-flow: center;
  align-items: space-between;

  .gallery-container {
    display: flex;
    flex-flow: row;
    margin: 0 0 10px 0;
    flex: 1 100%;
    overflow: hidden;
  }
}
</style>
