<template>
  <div v-if="!areShootingAndDateSet">
    <form @submit="inputData" ref="contract">
      <div class="content">
        <div class="container">
          <BasicInput style="margin: 15px 0;" class="xinput" name="Shooting" id="shooting" />
          <BasicInput style="margin: 15px 0;" type="date" name="date" id="date" />
          <BasicButton style="margin: 15px 0;" text="Weiter" faicon="paper-plane" type="submit" />
        </div>
      </div>
    </form>
  </div>
  <div v-else-if="success" style="height: 80vw; margin-top: 200px;" class="content card">
    <div class="container">
      <div class="wrapper">
        <fa-icon class="success-icon" icon="check" />
      </div>
    </div>
    <div class="container">
      <div class="wrapper">
        <h1 style="color: rgb(95, 168, 211);">Vielen Dank!</h1>
      </div>
    </div>
    <div class="container">
      <div class="wrapper">
        <p style="text-align: justify;">Ich freue mich schon auf das Shooting. Falls du noch Fragen hast oder mehr wissen möchtest, kannst du <a title="" href="/shooting-guide" target="_self">hier</a> mehr erfahren.</p>
      </div>
    </div>
  </div>
  <div v-else>
    <form @submit="submit" ref="contract">
      <div class="content">
        <div class="wrapper">
          <div class="container">
            <h1 style="margin-bottom: 50px">TFP-Vertrag</h1>
          </div>
          <div class="container">
            <h1 style="margin-bottom: 10px" class="contact-heading">Fotograf</h1>
          </div>
          <div class="container">
            <p style="margin-bottom: 50px" class="contact-heading">Evgenij Henning • Sudetenstraße 36 • 31515 Wunstorf</p>
          </div>
          <div class="container">
            <h1 class="contact-heading">Model</h1>
          </div>
          <div class="container">
            <BasicInput class="xinput" name="Name" id="firstname" />
            <BasicInput name="Nachname" id="lastname" class="xinput x-input-right" />
          </div>
          <div class="container">
            <BasicInput class="xinput" name="Straße" id="street" />
          </div>
          <div class="container">
            <BasicInput class="xinput" name="Postleizahl" id="zip" />
            <BasicInput class="xinput x-input-right" id="city" name="Ort" />
          </div>
          <div class="container">
            <BasicInput class="xinput" name="Handynummer (optional)" id="mobile" type="number" v-bind:required="false" />
            <BasicInput class="xinput x-input-right" name="E-Mail-Adresse" type="email" id="email" />
          </div>
          <div class="container">
            <h1 style="margin: 50px 0px 15px 0px" class="contact-heading">Art der Aufnahmen</h1>
          </div>
          <div class="container">
            <h2 style="color: black;">
              <b>{{ this.shooting }}</b>
            </h2>
          </div>
          <div class="container">
            <h1 style="margin: 50px 0px 15px 0px" class="contact-heading">Datum des Shootings</h1>
          </div>
          <div class="container">
            <h2 style="font-size: 20px; color: black;">
              <b>{{ this.date }}</b>
            </h2>
          </div>
          <div class="wrapper" v-html="page.content" ref="content"></div>
          <h3 class="contact-heading">SONDERVEREINBARUNGEN</h3>
          <div class="container">
            <BasicTextarea style="margin: 50px 0px 15px 0px" name="Vereinbarungen" id="other" :required="false" />
          </div>
          <div class="container">
            <h1 style="margin: 50px 0px 15px 0px" class="contact-heading">Unterschrift</h1>
          </div>
          <div class="container">
            <img v-if="signatureImage != null" :src="signatureImage" style="width: 400px;" @load="onImgLoad" />
          </div>
          <div class="container no-wrap">
            <BasicButton text="Unterschreiben" @btnClicked="showSignaturePad = true" faicon="signature" />
          </div>
          <p class="error" v-if="showError">Bitte die Unterschrift nicht vergessen</p>
          <div class="container no-wrap">
            <BasicButton text="Abschicken" faicon="paper-plane" type="submit" />
          </div>
        </div>
      </div>
    </form>

    <Modal v-model="showSignaturePad">
      <SignaturePad @action="handleSignature"></SignaturePad>
    </Modal>
  </div>

</template>

<script>
import contentService from '../services/content'

export default {
  created() {
    this.shooting = this.$route.query['shooting']
    this.date = this.$route.query['date']
    this.convertDate(this.date)
  },
  components: {
    BasicInput: () => import('../components/base/Input'),
    BasicTextarea: () => import('../components/base/Textarea'),
    Modal: () => import('../components/custom/FullscreenModal'),
    SignaturePad: () => import('../components/custom/SignatureArea'),
    BasicButton: () => import('../components/base/Button'),
  },
  computed: {
    areShootingAndDateSet() {
      return !(this.shooting == null || this.date == null)
    },
  },
  data: () => ({
    date: String,
    shooting: String,
    showSignaturePad: false,
    signatureImage: null,
    signatureImageReduced: null,
    showError: false,
    success: false,
  }),
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getDoubleDigitNumber(number) {
      return number < 10 ? '0' + number : '' + number
    },
    convertDate(inputDate) {
      const date = new Date(inputDate)
      this.date = date.toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
    },
    getContentForBackend(content) {
      var elements = []
      content.children.forEach((element) => {
        elements.push(element.innerText)
      })

      return elements
    },
    submit(event) {
      if (!this.signatureImageReduced) {
        this.showError = true
      }
      contentService.post('contract', {
        firstname: event.target.elements.firstname.value?.trim(),
        lastname: event.target.elements.lastname.value?.trim(),
        street: event.target.elements.street.value?.trim(),
        zipcode: event.target.elements.zip.value?.trim(),
        city: event.target.elements.city.value?.trim(),
        number: event.target.elements.mobile.value?.trim(),
        email: event.target.elements.email.value?.trim(),
        shooting: this.shooting.trim(),
        date: this.date, // Date noch passend umwandeln
        agreements: event.target.elements.other.value?.trim(),
        content: this.getContentForBackend(this.$refs.content),
        signature: this.signatureImageReduced,
      }).then(() => {
        this.success = true
        window.scrollTo(0,0)
      })

      event.preventDefault()
    },
    inputData(event) {
      this.$router.push(
        `/tfp?shooting=${event.target.elements.shooting.value}&date=${event.target.elements.date.value}`
      )
    },
    handleSignature(action) {
      this.showSignaturePad = false
      this.signatureImage = action.image
    },
    onImgLoad(event) {
      this.showError = false
      const canvas = document.createElement('canvas')

      canvas.width = event.target.width
      canvas.height = event.target.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height)

      this.signatureImageReduced = ctx.canvas.toDataURL(
        event.target,
        'image/png'
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .container {
    display: flex;
    flex-flow: row wrap;
    flex: 1 100%;
    left: 0;
    top: 0;
    justify-content: center;
    align-content: space-between;

    x-input {
      margin-top: 200px;
    }

    h3 {
      width: 100%;
    }

    .xinput {
      margin: 20px 0;
    }

    @media (min-width: 768px) {
      flex-flow: row;

      .x-input-right {
        margin-left: 20px;
      }
    }
  }

  .no-wrap {
    flex-flow: row;
    margin-bottom: 20px;
  }

  .submit-btn {
    display: block;
    margin-left: auto;
  }

  .success-icon {
    width: 50px;
    height: 50px;
    color: rgb(95, 168, 211);
  }
}
</style>
