<template>
  <div class="content">
    <h1 style="text-align: center;">
      Wilkommen in deinem ganz eigenen Bereich,
      {{ this.$store.getters.userName }}!
    </h1>
    <div v-html="page.content"></div>
    <div class="wrapper">
      <template v-for="(shooting, index) in shootings">
        <BasicImage
          v-if="shooting.image !== null"
          :key="index"
          class="card"
          :url="getUrlForId(shooting.image)"
          :title="shooting.date + ' ' + shooting.name"
          :isMouseOver="isDesktopView()"
          @imageClicked="navTo('shooting/' + shooting.id)"
        ></BasicImage>
      </template>
    </div>
  </div>
</template>

<script>
import contentService from '../services/content'
import BasicImage from '../components/base/Image'

export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BasicImage,
  },
  data: () => ({
    shootings: [],
  }),
  methods: {
    getUrlForId(id) {
      return contentService.getAssetsURL(id, 'reviews')
    },
    isDesktopView() {
      return window.innerWidth > 768
    },
    navTo(url) {
      this.$router.push(url)
    },
  },
  mounted() {
    if (this.$store.isAuthenticated == false) this.$router.push('/login')

    contentService.getItems('shootings').then(
      (response) => {
        response.forEach((shooting) => {
          this.shootings.unshift(shooting)
        })
      },
      (error) => {
        console.log(error)
      }
    )
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  // @media (min-width: 768px) {
  //   display: flex;
  // }

  .card {
    margin: 5px;
    cursor: pointer;
  }
}
</style>
