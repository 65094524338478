<template>
  <div class="container">
    <LazyImage :class="imageSet.class" :url="imageSet.url1" @imageClicked="$emit('imageClicked', imageSet.imageIndex1)" />
    <LazyImage :class="imageSet.class" class="right-card" v-if="imageSet.url2" :url="imageSet.url2" @imageClicked="$emit('imageClicked', imageSet.imageIndex2)" />
  </div>
</template>

<script>
import LazyImage from '../base/ImageLazy'

export default {
  components: {
    LazyImage,
  },
  props: {
    imageSet: {
      required: true,
      type: Object
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-flow: row;
  align-items: stretch;
  flex: 1 100%;
  overflow: hidden;

  .land {
    width: 100vw;
    height: calc(100vw * (2 / 3));
    max-height: 840px;
    max-width: 1260px;
  }

  .port {
    height: 75vw;
    width: calc(75vw * (2 / 3));

    max-height: 945px;
    max-width: 630px;
  }

  .land-port {
    width: calc(100vw * (2 / 3));
    max-width: 840px;

    height: calc((100vw * (2 / 3)) * (2 / 3));
    max-height: 560px;
  }

  .land-port.right-card {
    width: calc(100vw * (1 / 3));
    max-width: 420px;

    height: calc((100vw * (2 / 3)) * (2 / 3));
    max-height: 560px;
  }

  .port-land {
    width: calc(100vw * (1 / 3));
    max-width: 420px;

    height: calc((100vw * (2 / 3)) * (2 / 3));
    max-height: 560px;
  }

  .port-port {
    width: 50vw;
    height: 75vw;
    max-width: 630px;
    max-height: 970px;
  }

  .port-land.right-card {
    width: calc(100vw * (2 / 3));
    max-width: 840px;

    height: calc((100vw * (2 / 3)) * (2 / 3));
    max-height: 560px;
  }

  .right-card {
    margin: 0 0 0 10px;
  }
}
</style>
