export default {
  inserted: (el, binding) => {
    function attachClass() {
      var attachClass = true

      if (binding.value != null) {
        attachClass = binding.value()
      }

      if (attachClass && el) {
        setTimeout(() => {
          el.classList.add('loaded')
        }, 100)  
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        } else {
          attachClass()
          observer.unobserve(el)
        }
      })
    }

    if (!window['IntersectionObserver']) {
      attachClass()
    } else {
      const observer = new IntersectionObserver(handleIntersect, {
        root: null,
        threshold: '0.85'
      })

      observer.observe(el)
    }
  }
}
