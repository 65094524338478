<template>
  <div class="content">
    <div v-html="page.content"></div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
