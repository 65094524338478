<template>
  <label class="container">
    <input
      :type="type"
      :id="id"
      v-model="checked"
      :required="required"
      checked="checked"
      :name="name"
    />
    <span class="checkmark"></span>
    <span v-html="text" class="content-name"></span>
  </label>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'checkbox',
    },
    text: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    id: {
      type: String,
    },
    minlength: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    checked: false,
  }),
  methods: {
    handleChange(e) {
      this.$emit('handleChange', e.target.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border: 1px solid rgb(0, 0, 0);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgba(95, 168, 211, 0.2);
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: 1px solid rgb(95, 168, 211);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 13px;
  border: solid rgb(95, 168, 211);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
