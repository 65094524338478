import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { getRouter } from './router'
import Vue2TouchEvents from 'vue2-touch-events'
import Modal from './helpers/LoginModalPlugin'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faSignature, faPaperPlane, faDownload, faTimes, faAngleLeft, faAngleRight, faSignInAlt, faCheck, faUndo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret)
library.add(faSignature)
library.add(faPaperPlane)
library.add(faDownload)
library.add(faTimes)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faSignInAlt)
library.add(faCheck)
library.add(faUndo)

Vue.use(Modal)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: 'touch',
})

//Vue.config.productionTip = false

getRouter().then(router => {
  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
})
