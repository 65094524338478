<template>
  <div class="content">
    <div v-html="page.content"></div>
    <form class="wrapper" @submit="submit">
      <BasicInput class="xinput" name="Name" id="firstname" />
      <BasicInput class="xinput" name="E-Mail-Adresse" type="email" id="email" />
      <BasicInput class="xinput" name="Handynummer (optional)" id="number" type="number" v-bind:required="false" />
      <BasicTextarea class="textarea" name="Nachricht" id="message" />
      <BasicCheckbox text='Es gilt die <a style="font-size: 13px;" href="/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a>' class="submit-btn" :required="true" />
      <p v-if="showMsg" class="msg">Vielen Dank für deine Nachricht!</p>
      <BasicButton text="Abschicken" faicon="paper-plane" type="submit" class="submit-btn" />
    </form>
  </div>
</template>

<script>
import contentService from '../services/content'
import BasicInput from '../components/base/Input'
import BasicTextarea from '../components/base/Textarea'
import BasicButton from '../components/base/Button'
import BasicCheckbox from '../components/base/Checkbox'

export default {
  components: {
    BasicInput,
    BasicTextarea,
    BasicButton,
    BasicCheckbox,
  },
  data: () => ({
    name: '',
    email: '',
    msg: '',
    showMsg: false,
  }),
  props: {
    page: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    submit(event) {
      event.preventDefault()
      contentService
        .post('contact', {
          name: event.target.elements.firstname.value,
          email: event.target.elements.email.value,
          text: event.target.elements.message.value,
          number: event.target.elements.number.value,
        })
        .then(() => {
          this.showMsg = true
          event.target.elements.firstname.value = ''
          event.target.elements.email.value = ''
          event.target.elements.message.value = ''
          event.target.elements.number.value = ''
          event.target.elements.checkbox.checked = false
        })
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  max-width: 960px;

  .wrapper {
    justify-content: center;
    align-items: center;

    .msg {
      margin-top: 10px;
      color: rgb(95, 168, 211);
    }
  }
}

.xinput {
  margin: 25px 0;
}

.submit-btn {
  margin: 25px 0 0 auto;
}
</style>
