<template>
  <div class="input-group">
    <input
      :value="text"
      :type="type"
      :name="id"
      :id="id"
      :required="required"
      :minlength="minlength"
      placeholder=" "
    />
    <label class="label-name" :for="id">
      <span class="content-name">{{ name }}</span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="underline"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'InputBox',
    },
    text: {
      default: ''
    },
    required: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
    },
    minlength: {
      type: Number,
      default: 3,
    },
  },
  methods: {
    handleChange(e) {
      this.$emit('handleChange', e.target.value)
    },
  }
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
  position: relative;
  height: 45px;
}

input {
  width: 100%;
  height: 100%;
  color: #595f6e;
  border: none;
  outline: none;
  font-size: 17px;
  background: transparent;
  height: 60px;
  padding-left: 5px;
}

label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 2px;
  transition: all 0.5s ease;
  background: transparent;
  z-index: 1;
  padding: 0;
}

// Name der nach oben fliegt
input:focus + .label-name .content-name,
input:not(:placeholder-shown) + .label-name .content-name {
  transform: translateY(-180%);
  font-size: 14px;
  left: 5px;
  padding: 0 3px;
  color: rgb(95, 168, 211);
  background: white;
}

// Blauer Balken nach der Animation
input:focus + .label-name::after,
input:not(:placeholder-shown) + .label-name::after {
  transform: translateX(0%);
}

.input-group .borders {
  display: block;
  position: absolute;
  background: rgb(95, 168, 211);
}

.input-group .underline {
  display: block;
  position: absolute;
  background: rgb(0, 0, 0);
}

.label-name span:nth-child(2),
input:not(:placeholder-shown) + .label-name span:nth-child(2) {
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

input:focus + .label-name span:nth-child(2),
input:not(:placeholder-shown) + .label-name span:nth-child(2) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.label-name span:nth-child(3),
input:not(:placeholder-shown) + .label-name span:nth-child(3) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

input:focus + .label-name span:nth-child(3),
input:not(:placeholder-shown) + .label-name span:nth-child(3) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}

.label-name span:nth-child(4),
input:not(:placeholder-shown) + .label-name span:nth-child(4) {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
}

input:focus + .label-name span:nth-child(4),
input:not(:placeholder-shown) + .label-name span:nth-child(4) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

.label-name span:nth-child(5),
input:not(:placeholder-shown) + .label-name span:nth-child(5) {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

input:focus + .label-name span:nth-child(5),
input:not(:placeholder-shown) + .label-name span:nth-child(5) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

.label-name span:nth-child(6),
input:not(:placeholder-shown) + .label-name span:nth-child(6) {
  left: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

input:focus + .label-name span:nth-child(6),
input:not(:placeholder-shown) + .label-name span:nth-child(6) {
  transform: scaleX(0);
  height: 1px;
  transform-origin: right;
  transition: transform 0.5s;
}
</style>
