<template>
  <div>
    <header :class="classes" id="header">
      <Menu v-model="menuOpen" @action="handleAction" />
      <router-link class="logo" to="/" @click.native="menuOpen = false" >
      <img style="width: 30px;" src="./assets/logo.png" alt="" />
      </router-link>
      <a class="menu-icon" href="#" :class="classes" @click="openMenu">
        <span class="menu-icon__line"></span>
      </a>
    </header>
    <main>
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" />
      </transition>
      <div v-if="showModal" class="modal-route">
        <div class="modal-content">
          <router-view></router-view>
        </div>
      </div>
    </main>
    <Footer />
    <x-login-modal />
  </div>
</template>

<script>
import Menu from './components/custom/Menu'
import Footer from './components/custom/Footer'
import { AUTH_LOGOUT } from './store/actions/auth'

export default {
  components: {
    Menu,
    Footer,
  },
  data: () => ({
    showLogin: false,
    menuOpen: false,
    showModal: false,
  }),
  computed: {
    classes() {
      return {
        menuOpen: this.menuOpen,
      }
    },
  },
  watch: {
    menuOpen(to) {
      document.body.style.overflowY = to ? 'hidden' : 'auto'
    },
  },
  methods: {
    openMenu(event) {
      event.preventDefault()
      this.menuOpen = !this.menuOpen
    },
    handleAction(action) {
      this.menuOpen = false

      if (action === 'logout') {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          if (this.$route.name != 'Startseite') {
            this.$router.push('/')
          }
        })
      }
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height
    },
    enter(element) {
      const { height } = getComputedStyle(element)

      element.style.height = this.prevHeight

      setTimeout(() => {
        element.style.height = height
      })
    },
    afterEnter(element) {
      element.style.height = 'auto'
    },
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(136, 136, 136, 0.233);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.233);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.164);
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'Montserrat', Tahoma, Geneva, Verdana, sans-serif;
  color: rgb(58, 58, 58);
  font-size: 13px;
}

.content {
  max-width: 1280px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  font-size: 16px;
  color: rgb(61, 54, 122);
}

p {
  padding-bottom: 5px;
  line-height: 150%;
  font-size: 17px;
  // font-weight: 900;
}

h1,
h2,
h3,
h4 {
  line-height: 150%;
  color: #e6af91;
  letter-spacing: 1px;
}

ul {
  padding: 0 0 0 30px;
  list-style-type: none;
}

li {
  margin-bottom: 5px;
}

span {
  font-size: 13px;
  font-weight: bolder;
}

.img-btn {
  padding: 8px 8px;
  font-size: 26px;
  cursor: pointer;
  background: transparent;
  color: white;
  border: none;
}

.menuOpen {
  bottom: 0;

  @media (min-width: 768px) {
    bottom: auto;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .logo {
    position: fixed;
    top: 8px;
    left: 2%;

    @media (min-width: 768px) {
      top: 4px;
    }
  }

  .menu-icon {
    position: fixed;
    padding: 10px 10px;
    z-index: 9;
    right: 10px;
    top: 13px;
    height: 25px;
    opacity: 1;

    &__line {
      display: block;
      position: relative;
      background: rgb(255, 255, 255);
      height: 2px;
      width: 20px;
      border-radius: 4px;
      transition: background-color 0.8s ease;

      &::before,
      &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background: rgb(255, 255, 255);
        transition: background-color 0.8s ease;
      }

      &::before {
        transform: translateY(-5px);
      }

      &::after {
        transform: translateY(5px);
      }
    }

    &.menuOpen > * {
      background: white;
      animation: openMid 0.8s forwards;

      &::before,
      &::after,
      * {
        background: white;
      }

      &::before {
        animation: openTop 0.8s forwards;
      }

      &::after {
        animation: openBtm 0.8s forwards;
      }
    }

    &:not(.menuOpen) > * {
      background: rgb(255, 255, 255);
      animation: closeMid 0.8s reverse;

      &::before,
      &::after,
      * {
        background: rgb(255, 255, 255);
      }

      &::before {
        animation: closeTop 0.8s reverse;
      }

      &::after {
        animation: closeBtm 0.8s reverse;
      }
    }
  }

  @media (min-width: 768px) {
    bottom: auto;

    .menu-icon {
      display: none;
    }
  }
}

.invisible {
  display: none;
}

main {
  position: relative;
  z-index: 1;
  min-height: 92.3vh;
  margin-top: 45px;

  & > * > * {
    padding: 10px 10px 0 10px;
  }

  @media (min-width: 768px) {
    padding-top: 60px;
    margin-top: 0;
  }
}

footer {
  margin: 10px 10px;
  padding: 10px 0 0 0;
  border-top: 1px solid #ccc;
  position: absolute;
  bottom: 0;
  width: 100%;
}

@keyframes openTop {
  0% {
    transform: translateY(-5px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes openMid {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes openBtm {
  0% {
    transform: translateY(5px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes closeTop {
  0% {
    transform: translateY(-5px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

@keyframes closeMid {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes closeBtm {
  0% {
    transform: translateY(5px) rotate(0deg);
  }
  50% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(0px) rotate(90deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: rgb(0, 0, 0);
  transition: background-color 5000s ease-in-out 5000s;
}
</style>
