import Vue from 'vue'
import VueRouter from 'vue-router'
import contentService from '../services/content'
import Page from '../views/Page'
import store from '../store/index'

Vue.use(VueRouter)

function getRouter() {
  return new Promise((resolve, reject) => {
    contentService
      .getPages('fields=id,path,title,show_in_menu,not_found_flag,public,status')
      .then(result => {
        const routes = []

        result.data.forEach(page => {
          routes.push({
            path: page.path,
            name: page.title,
            alias: page.not_found_flag ? '*' : '',
            component: Page,
            props: {
              id: page.id,
            },
            meta: {
              showInMenu: page.show_in_menu,
              requiresAuth: !page.public,
              isPublished: page.status == 'published',
            },
          })
        })

        const router = new VueRouter({
          routes,
          mode: 'history',
          scrollBehavior() {
            return { x: 0, y: 0 }
          },
        })

        router.beforeEach((to, from, next) => {
          if (to.meta.isPublished) {
            next()
          } else {
            return
          }
        })

        router.beforeEach((to, from, next) => {
          if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) {
              next()
              return
            }
            next(from.fullPath)
          } else {
            next()
          }
        })

        return resolve(router)
      })
      .catch(error => {
        return reject(error)
      })
  })
}

export { getRouter }
