<template>
  <div v-if="show" class="modal">
    <button class="btn-close img-btn" :class="buttonPosition" @click="close">
      <fa-icon icon="times" />
    </button>
    <slot name="default" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    buttonPosition: {
      type: String,
      default: 'center',
    },
  },
  data: () => ({
    show: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.show = to
      },
    },
    show: {
      immediate: true,
      handler(to) {
        document.body.style.overflow = to ? 'hidden' : 'auto'
        var header = document.getElementById('header')

        if (to) {
          header.classList.add('invisible')
        } else {
          header.classList.remove('invisible')
        }
        this.$emit('input', to)
      },
    },
  },
  beforeDestroy(to) {
    document.body.style.overflow = to ? 'hidden' : 'auto'
    var header = document.getElementById('header')
    header.classList.remove('invisible')
  },
  methods: {
    close() {
      this.show = false
    },
    boxClick(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.98);
  z-index: 9;
  overflow: hidden;
}

.btn-close {
  position: absolute;
  bottom: 0.5%;
  z-index: 4;
  transform: translate(-50%, 0);
}

.center {
  left: 50%;
}

.left {
  left: 33%;
}

.right {
  left: 66%;
}
</style>
