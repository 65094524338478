<template>
  <button :type="type" :name="name" @click="$emit('btnClicked')">
    <span class="button__icon">
      <fa-icon :icon="faicon" />
    </span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'btn',
    },
    type: {
      type: String,
      default: 'button',
    },
    faicon: {
      type: String,
      default: 'fas-check',
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  z-index: 9;
  height: 50px;
  width: 50px;
  background: #e6986f;
  border: none;
  border-radius: 50%;
  position: fixed;
  bottom: 9%;
  right: 5%;
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
}

button:hover {
  background: #d48a63;
}

button:active {
  background: #e78754;
}

.button__icon {
  font-size: 26px;
}
</style>
