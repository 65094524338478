<template>
  <nav v-on:scroll.passive="handleScroll" :class="classes">
    <div :class="classes" class="nav-entries">
      <div v-for="(link, index) in links" :key="index" class="nav-entry">
        <a class="link" v-if="link.action" @click="link.action" href="">{{
          link.name
        }}</a>
        <router-link
          v-else
          :key="index"
          class="link"
          :to="link.path"
          @click.native="close"
          >{{ link.name }}</router-link
        >

        <div
          v-if="link.subLinks != null && link.subLinks.length > 0"
          class="dropdown"
          :class="classes"
          :key="index + 100"
        >
          <template v-for="(subLink, subIndex) in link.subLinks">
            <router-link
              class="sub link"
              :key="subIndex"
              :to="subLink.path"
              @click.native="close"
              >{{ subLink.name }}</router-link
            >
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isScrolled: false,
  }),
  computed: {
    classes() {
      return {
        scrolled: this.isScrolled,
        open: this.value,
        home: this.home,
      }
    },
    home() {
      return this.$route.path === '/'
    },
    links() {
      let menuLinks = this.$router.options.routes.filter(
        (route) => route.meta.showInMenu && route.meta.isPublished
      )
      let allLinks = this.$router.options.routes

      menuLinks.forEach((menuLink) => {
        const subLinks = []

        allLinks.forEach((link) => {
          if (link.path != menuLink.path && link.path.includes(menuLink.path))
            subLinks.push(link)
        })
        menuLink.subLinks = subLinks
      })

      menuLinks = menuLinks.filter(
        (link) =>
          !(link.meta.requiresAuth && !this.$store.getters.isAuthenticated)
      )

      if (!this.$store.getters.isAuthenticated) {
        menuLinks.push({
          name: 'Login',
          action: (e) => {
            e.preventDefault()
            this.$modal.show()
          },
        })
      } else {
        menuLinks.push({
          name: 'Logout',
          action: (e) => {
            e.preventDefault()
            this.$emit('action', 'logout')
          },
        })
      }

      return menuLinks
    },
    getOpened() {
      if (this.opened) {
        return 'open'
      }

      return ''
    },
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    handleScroll() {
      const scrollPos = window.scrollY
      if (scrollPos > 10) {
        this.isScrolled = true
      } else {
        this.isScrolled = false
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="scss">
nav {
  height: 50px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 3px 18px 1px black;

  .nav-entries {
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9);
    height: 100vh;
    width: 100%;
    clip-path: circle(100px at 105% -5%);
    -webkit-clip-path: circle(100px at 105% -5%);
    transition: clip-path 0.7s, opacity 0.5s ease-out;
    opacity: 0;
    pointer-events: none;

    .nav-entry {
      display: flex;
      flex-flow: column;
      justify-content: center;
      transform: translateX(100%);

      .link {
        text-align: center;
        color: white;
        font-size: 23px;
        margin: 10px 0;
        text-align: center;
        font-weight: bold;
        width: 100%;
      }

      .sub {
        font-size: 20px;
      }
    }

    .dropdown {
      display: flex;
      flex-flow: column;
    }
  }

  .nav-entries.open {
    opacity: 1;
    clip-path: circle(1300px at 100% 0%);
    -webkit-clip-path: circle(1300px at 100% 0%);
    pointer-events: all;

    .nav-entry {
      transform: translateX(0);
      transition: opacity 0.4s ease-in-out,
        transform 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275);

      &:nth-of-type(1) {
        transition-delay: 0.3s;
      }
      &:nth-of-type(2) {
        transition-delay: 0.4s;
      }
      &:nth-of-type(3) {
        transition-delay: 0.5s;
      }
      &:nth-of-type(4) {
        transition-delay: 0.6s;
      }
      &:nth-of-type(5) {
        transition-delay: 0.7s;
      }
      &:nth-of-type(6) {
        transition-delay: 0.8s;
      }
      &:nth-of-type(7) {
        transition-delay: 0.9s;
      }
    }
  }
}

@media (min-width: 768px) {
  nav {
    height: 40px;
    background: rgba(0, 0, 0, 0.9);
    transition: background-color 0.5s;

    .nav-entries {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      background: transparent;
      height: 40px;
      clip-path: none;
      opacity: 1;
      transition: height 0.4s;
      pointer-events: all;

      .nav-entry {
        transform: translateX(0);
        padding: 7px 7px;

        .link {
          transform: translateX(0);
          margin: 0;
          padding: 9px 10px;
          opacity: 1;
          font-size: 15px;
          font-weight: normal;
        }

        .sub {
          margin: 0 0 0 -5px;
        }

        .dropdown {
          background: rgba(0, 0, 0, 0.9);
          width: 100%;
          opacity: 0;
          flex-flow: column;
          position: absolute;
          transform: translateY(20px);
          transition: all 0.5s;
        }

        .dropdown.home:not(.scrolled) {
          background: linear-gradient(
            rgba(0, 0, 0, 0.075),
            rgba(0, 0, 0, 0.541)
          );
          transition: all 0.5s;
          box-shadow: none;
        }
      }

      .nav-entry:hover .dropdown {
        display: flex;
        transition: all 0.5s;
        transform: translateY(55px);
        opacity: 1;
      }
    }
  }

  nav.home:not(.scrolled) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transition: background-color 0.9s;
    box-shadow: none;
  }

  nav.scrolled {
    height: 40px;
    background: rgba(0, 0, 0, 0.9);
    transition: all 0.9s;
  }
}
</style>
