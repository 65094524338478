<template>
  <div v-if="show" class="modal" @mousedown="close">
    <div class="box" v-bind:style="{ width: boxWidth + 'px' }" @mousedown="boxClick">
      <slot name="default" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    boxWidth: {
      type: Number,
      default: 380,
    },
  },
  data: () => ({
    show: false,
  }),
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.show = to
      },
    },
    show: {
      immediate: true,
      handler(to) {
        document.body.style.overflow = to ? 'hidden' : 'auto'
        this.$emit('input', to)
      },
    },
  },
  methods: {
    close() {
      this.show = false
    },
    boxClick(event) {
      event.stopPropagation()
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9;

  .box {
    padding: 120px 40px 40px;
    background: rgb(255, 255, 255);
    // border-radius: 10px;
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.7);
    border: 1px solid #fff;
  }
}
</style>
