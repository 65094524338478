<template>
  <component v-bind:is="layout" :page="page" :settings="settings"></component>
</template>

<script>
import contentService from '../services/content'
import PageDefault from './Default'
import PageHome from './Home'
import PageContact from './Contact'
import PageTfp from './Tfp'
import PageReviews from './Reviews'
import PagePortfolio from './Portfolio'
import PagePortfolioWall from './PortfolioWall'
import PageShootingList from './ShootingList'
import PageShooting from './Shooting'

export default {
  components: {
    PageDefault,
    PageHome,
    PageContact,
    PageTfp,
    PageReviews,
    PagePortfolio,
    PagePortfolioWall,
    PageShootingList,
    PageShooting,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    page: {},
    component: '',
    settings: {},
  }),
  computed: {
    layout() {
      return this.component.length ? 'Page' + this.component : ''
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(to) {
        contentService
          .getResolvedPages(
            to,
            `?fields=*,images.directus_files_id.id,images.directus_files_id.filename_download,images.directus_files_id.filesize,images.directus_files_id.width,images.directus_files_id.height,images.id`
          )
          .then((result) => {
            this.page = result.data

            contentService.getLayouts(result.data.layout).then((result) => {
              this.component = result.data.component
              this.settings = result.data.settings
            })
            document.title = this.page.title + ' - Evgenij Henning'
            document.querySelector('meta[name="og:title"]').content =
              this.page.title + ' - Evgenij Henning'

            document.querySelector(
              'meta[name="description"]'
            ).content = this.page.description
            document.querySelector(
              'meta[name="og:description"]'
            ).content = this.page.description
          })
      },
    },
  },
}
</script>
