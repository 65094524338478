<template>
  <div class="input-group">
    <textarea
      class="textarea"
      :type="type"
      :name="id"
      :id="id"
      :required="required"
      placeholder=" "
    ></textarea>
    <label class="label-name" :for="id">
      <span class="content-name">{{ name }}</span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="borders"></span>
      <span class="underline"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String,
    },
    name: {
      type: String,
      default: 'Textarea',
    },
    required: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
    },
  },
  data: () => ({
    content: '',
  }),
  mounted() {
    let textarea = document.querySelector('.input-group .textarea')
    textarea.addEventListener('keyup', () => {
      let numberOfLineBreaks = (textarea.value.match(/\n/g) || []).length
      if (numberOfLineBreaks < 1) {
        textarea.style.height = 55 + numberOfLineBreaks * 20 + 'px'
      } else {
        textarea.style.height = 35 + numberOfLineBreaks * 20 + 'px'
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  width: 100%;
  position: relative;

  textarea {
    border: none;
    padding-top: 15px;
    font-size: 17px;
    overflow: hidden;
    outline: none;
    resize: none;
    color: #595f6e;
    width: 100%;
    height: 55px;
    padding-left: 5px;
    font-family: inherit;
  }
}

label {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.content-name {
  position: absolute;
  top: 37px;
  left: 2px;
  transition: all 0.5s ease;
  background: transparent;
  z-index: 1;
  padding: 0;
}

// Name der nach oben fliegt
textarea:focus + .label-name .content-name,
textarea:not(:placeholder-shown) + .label-name .content-name {
  transform: translateY(-265%);
  font-size: 14px;
  left: 5px;
  padding: 0 3px;
  color: rgb(95, 168, 211);
  background: white;
}

// Blauer Balken nach der Animation
textarea:focus + .label-name::after,
textarea:not(:placeholder-shown) + .label-name::after {
  transform: translateX(0%);
}

.input-group .borders {
  display: block;
  position: absolute;
  background: rgb(95, 168, 211);
}

.input-group .underline {
  display: block;
  position: absolute;
  background: rgb(0, 0, 0);
}

.label-name span:nth-child(2),
textarea:not(:placeholder-shown) + .label-name span:nth-child(2) {
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

textarea:focus + .label-name span:nth-child(2),
textarea:not(:placeholder-shown) + .label-name span:nth-child(2) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.label-name span:nth-child(3),
textarea:not(:placeholder-shown) + .label-name span:nth-child(3) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

textarea:focus + .label-name span:nth-child(3),
textarea:not(:placeholder-shown) + .label-name span:nth-child(3) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}

.label-name span:nth-child(4),
textarea:not(:placeholder-shown) + .label-name span:nth-child(4) {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
}

textarea:focus + .label-name span:nth-child(4),
textarea:not(:placeholder-shown) + .label-name span:nth-child(4) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

.label-name span:nth-child(5),
textarea:not(:placeholder-shown) + .label-name span:nth-child(5) {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

textarea:focus + .label-name span:nth-child(5),
textarea:not(:placeholder-shown) + .label-name span:nth-child(5) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

.label-name span:nth-child(6),
textarea:not(:placeholder-shown) + .label-name span:nth-child(6) {
  left: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  width: 100%;
  height: 1px;
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.5s;
  transition-delay: 0.5s;
}

textarea:focus + .label-name span:nth-child(6),
textarea:not(:placeholder-shown) + .label-name span:nth-child(6) {
  transform: scaleX(0);
  height: 1px;
  transform-origin: right;
  transition: transform 0.5s;
}
</style>
